<template>
  <v-fade-transition>
    <v-container fluid class="pa-0" v-show="noCredentials">
      <v-alert type="info" text >
        Recuerda que puedes configurar pagos con MercadoPago de manera sencilla, segura y gratuita.

        <br />
        <v-btn color="primary"  class="mt-2 mr-2" href="https://www.mercadopago.com.uy/ayuda/33399" target="_blank">
          <v-icon left>mdi-link</v-icon>
          Ver costos</v-btn>

        <v-btn color="primary"   class="mt-2" @click="mercadoPagoConfigurationDialog = true;">
          <v-icon left>
            mdi-cog
          </v-icon>
          Configurar
        </v-btn>
      </v-alert>

      <v-dialog v-model="mercadoPagoConfigurationDialog">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-cog</v-icon>
            MercadoPago
            <v-spacer></v-spacer>
            <v-btn icon @click="mercadoPagoConfigurationDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">Cuenta MercadoPago</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">Configuración de Credenciales</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 3" step="3">Test de pago</v-stepper-step>

              </v-stepper-header>

              <v-stepper-items>

                <v-stepper-content :step="1">
                  <p>Para comenzar procesar pagos debes tener una cuenta en MercadoPago, si no tienes una puedes crearla
                    en
                    el siguiente enlace: <a href="https://www.mercadopago.com" target="_blank"> Crear cuenta
                      MercadoPago</a>
                  </p>

                  <p>
                    Si ya tienes una cuenta, debes crear una aplicación en MercadoPago Developers, para ello debes
                    seguir
                    los siguientes pasos:
                  </p>
                  <ol>
                    <li>Ingresa a <a href="https://www.mercadopago.com/developers/panel" target="_blank">MercadoPago
                        Developers</a></li>
                    <li>En la sección de aplicaciones, haz clic en "Crear aplicación"</li>
                    <li>Completa los datos solicitados y haz clic en "Crear aplicación"</li>
                    <li>Ve a "credenciales de produccion" y copia los datos de la aplicación creada y pégala en los
                      campos
                      correspondientes</li>
                  </ol>

                  <v-btn color="primary" @click="step = 2" class="mt-4">
                    <v-icon left>
                      mdi-arrow-right
                    </v-icon>
                    Siguiente
                  </v-btn>


                </v-stepper-content>



                <v-stepper-content :step="2">
                  <v-form ref="form">
                    <p>
                      Ingresa los datos de tu aplicación de MercadoPago
                    </p>

                    <v-text-field v-model="mercadoPagoConfiguration.clientId" label="Client ID" filled dense
                      hide-details="" required :rules="[rules.required]" single-line></v-text-field>
                    <v-text-field v-model="mercadoPagoConfiguration.clientSecret" label="Client Secret"
                      :type="showClientSecret ? 'password' : 'text'" filled dense hide-details="" required
                      :rules="[rules.required]" single-line>
                      <template v-slot:append>
                        <v-icon @click="showClientSecret = !showClientSecret">
                          {{ showClientSecret ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-text-field v-model="mercadoPagoConfiguration.publicKey" label="Public Key" filled dense
                      hide-details="" required :rules="[rules.required]" single-line></v-text-field>
                    <v-text-field v-model="mercadoPagoConfiguration.accessToken" label="Access Token"
                      :type="showAccessToken ? 'password' : 'text'" filled dense hide-details="" required
                      :rules="[rules.required]" single-line>
                      <template v-slot:append>
                        <v-icon @click="showAccessToken = !showAccessToken">
                          {{ showAccessToken ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>

                  </v-form>
                  <v-btn @click="step = 1" class="mr-2 mt-4">
                    <v-icon left>
                      mdi-arrow-left
                    </v-icon>
                    Atras
                  </v-btn>

                  <v-btn class="mt-4" color="primary" @click="validate">
                    <v-icon left>
                      mdi-arrow-right
                    </v-icon>
                    Siguiente
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content :step="3">
                  A continuación, puedes realizar un test de pago para verificar que la configuración de MercadoPago es
                  correcta.

                  <br />
                  <v-btn @click="step = 2" class="mr-2 mt-4">
                    <v-icon left>
                      mdi-arrow-left
                    </v-icon>
                    Atras
                  </v-btn>

                </v-stepper-content>
              </v-stepper-items>

            </v-stepper>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-fade-transition>
</template>

<script>
import { collection, getDocs, setDoc, getDoc, addDoc, updateDoc, doc, deleteDoc, getFirestore } from 'firebase/firestore';

export default {
  name: 'SetupMercadoPago',
  data() {
    return {
      noCredentials: false,
      step: 1,
      showClientSecret: false,
      showAccessToken: false,
      mercadoPagoConfiguration: {},
      mercadoPagoConfigurationDialog: false,
      rules: {
        required: value => !!value || 'Requerido.',
      },
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveMercadoPagoConfiguration();
        this.step = 3;
      } else {
        this.$notify({
          title: 'Error',
          text: 'Por favor, complete los campos requeridos',
          type: 'error'
        });
      }
    },
    //check if credentials are configured in the /configuration/mercadopago
    async checkCredentials() {
      const db = getFirestore();
      const docRef = doc(db, "configurations", "mercadopago");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.noCredentials = false;
        this.mercadoPagoConfiguration = docSnap.data()
      } else {
        this.noCredentials = true;
      }
    },

    async saveMercadoPagoConfiguration() {
      const db = getFirestore();
      const docRef = doc(db, "configurations", "mercadopago");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, this.mercadoPagoConfiguration);
      } else {
        await setDoc(docRef, this.mercadoPagoConfiguration);
      }
      this.$notify({
        title: 'Éxito',
        text: 'Configuración de MercadoPago guardada correctamente',
        type: 'success'
      });
      this.mercadoPagoConfigurationDialog = false;
      this.noCredentials = false;
    }


  },
  mounted() {
    this.checkCredentials();

  }
};
</script>


<style>
/* Style adjustments as necessary */
</style>